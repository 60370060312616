import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import axios from 'axios';
import { API_URL, BASE_URL } from '../../config/Constants'
import filreset from '../../assets/images/lock_reset (1).png'
import Golfholidayimg from '../../assets/images/image 84.png'
import HolidayList from './HolidayList';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp,faArrowRight,faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import rectangle1 from '../../assets/images/Rectangle 18.png'
import rectangle2 from '../../assets/images/Rectangle 19.png'
import rectangle3 from '../../assets/images/Rectangle 20.png'
import rectangle4 from '../../assets/images/Rectangle 21.png'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GolfHolidays = () => {


  const { userCurrencyCode, currencyRate } = useSelector(state => state.currency);
  const [currencyRateList, setCurrencyRateList] = useState([]);

  const { availableHolidays } = useSelector(state => state.booking)
  const [golfHolidays, setGolfHolidays] = useState(availableHolidays);
  const [searchdata, setSearchdata] = useState("");
  const navigation = useNavigate();
  const [countriess, setCountries] = useState([]);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [filteredminimumPrice, setFilMinimumPrice] = useState(0);
  const [filteredmaximumPrice, setFilMaximumPrice] = useState(0);

  const GolfHolidayDetailsPage = () => {
    setAnchorElNav(null);
    navigation('/GolfHolidayDetailsPage')
};

  useEffect(() => {
    preFunction()
  }, [availableHolidays, currencyRateList]);



  useEffect(() => {
    if (currencyRate) {
      setCurrencyRateList(currencyRate)
    }
  }, [currencyRate]);



  const preFunction = () => {
    // Retrieve search data from local storage and parse it
    const searchData = localStorage.getItem("search");
    if (searchData) {
      setSearchdata(JSON.parse(searchData));
    }

    if (availableHolidays) {
      setGolfHolidays(availableHolidays);

      // Find the lowest price among all up_golfer_single prices
      const allPrices = []
      if (golfHolidays && golfHolidays?.length > 0) {

        golfHolidays.forEach((holiday) => {
          holiday.holiday_price.forEach((each) => {
            const currencyPerPrice = currencyRateList[each?.currency_id?.currencyCode];
            const convertedPrice = each.up_golfer_single / currencyPerPrice
            allPrices.push(convertedPrice > 0 ? convertedPrice : 0)
          });
        });

      }

      //   const allPrices = golfHolidays.flatMap(holiday =>
      //   holiday.holiday_price?.map(item => item.up_golfer_single) || []
      // );

      if (allPrices.length > 0) {
        const highestPrice = Math.max(...allPrices);
        const roundedPrice = Math.ceil(highestPrice + 10);
        setMaximumPrice(roundedPrice);
        setFilMaximumPrice(roundedPrice);
      }

      // Filter countries (assuming this is a function to update some state)
      filterCountries();
    }
  }

  const filterCountries = () => {
    var lists = [];
    availableHolidays.forEach((holiday) => {
      if (Array.isArray(holiday?.golf_holidays?.country_id)) {
        holiday?.golf_holidays?.country_id.forEach((country) => {
          if (country.name && !lists.includes(country.name)) {
            lists.push(country.name);
          }
        });
      }
    });

    setCountries(lists)

  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { user } = useSelector(state => state.auth);


  // ----date--------------
  const [startDate, setStartDate] = useState(new Date());


  // --------new-----------------
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedSorts, setSelectedSorts] = useState([]);

  const [openDropdown, setOpenDropdown] = useState(null);

  const locations = ["Asia", "Europe", "Africa", "North America", "South America", "Australia"];
  const cities = ["New York", "London", "Paris", "Tokyo", "Sydney"];
  const durations = ["1-3 Days", "3-6 Days", "1 Week"];
  const themes = ["Events", "Adventure", "Relaxation"];
  const sorts = ["Popular", "Latest", "Best Deal"];

  const handleCheckboxSelection = (selectedOptions, setSelectedOptions, option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };



  const packages = [
    {
      image1:rectangle1,
      image2:rectangle2,
      image3:rectangle3,
      title: 'Golf & Ayurveda in South India',
      location: 'Bangalore, Ooty, Kochi & Chennai, India',
      days: '12 Nights | 4 Rounds',
      price: 894.95,
      rating: 4.8,
      describtion: "Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala. Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.",
    },
    {
      image1:rectangle1,
      image2:rectangle2,
      image3:rectangle3,
      title: 'Golf & Ayurveda in North Indiaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
      location: 'Bangalore & Chennai, India',
      days: '10 Nights | 3 Rounds',
      price: 799.95,
      rating: 4.5,
      describtion: "Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.",
    },
    {
      image1:rectangle1,
      image2:rectangle2,
      image3:rectangle3,
      title: 'Golf & Ayurveda in North India',
      location: 'North India',
      days: '10 Nights | 3 Rounds',
      price: 799.95,
      rating: 4.5,
      describtion: 'nithish',
    },
    {
      image1:rectangle1,
      image2:rectangle2,
      image3:rectangle3,
      title: 'Golf & Ayurveda in North India',
      location: 'North India',
      days: '10 Nights | 3 Rounds',
      price: 799.95,
      rating: 4.5,
    },
  ];





  return (
    <div className='listitem'>
      <div className='listitems-sec'>
        <p className='final-list-path'>Home <span className='arrow-button'><FontAwesomeIcon icon={faChevronUp} className="arrow-icon left" /></span> Golf Holiday</p>
        <h2 className='final-list-head'>Golf Holiday Packages</h2>
        <p className='final-list-subtit'>Office ipsum you must be muted. T-shaped can air race hanging. Respectively social teams new lift before pee red-flag busy of. Team market live businesses masking.</p>
        <Grid container spacing={2} className='filter-final-fullbox'>
          <Grid item xs={12} className='filter-inner-box-final'>
            <div className="filter-bar-grid-final">
            <Grid container spacing={2} className='d-s-b'>
              <Grid item xs={9} className='filter-left-final'>
                <div className="filter-options-group-final">
                  <div className="dropdown-final">
                    <label htmlFor="location-final">Location:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("location")}>
                        {selectedLocations.length > 0 ? selectedLocations.join(", ") : "Select Locations"}
                        <FontAwesomeIcon icon={openDropdown === "location" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "location" && (
                        <div className="checkbox-dropdown-content-final">
                          {locations.map((location) => (
                            <label key={location}>
                              <input
                                type="checkbox"
                                checked={selectedLocations.includes(location)}
                                onChange={() => handleCheckboxSelection(selectedLocations, setSelectedLocations, location)}
                              />
                              {location}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("city")}>
                        {selectedCities.length > 0 ? selectedCities.join(", ") : "Select Cities"}
                        <FontAwesomeIcon icon={openDropdown === "city" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "city" && (
                        <div className="checkbox-dropdown-content-final">
                          {cities.map((city) => (
                            <label key={city}>
                              <input
                                type="checkbox"
                                checked={selectedCities.includes(city)}
                                onChange={() => handleCheckboxSelection(selectedCities, setSelectedCities, city)}
                              />
                              {city}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dropdown-final">
                    <label htmlFor="duration-final">Duration:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("duration")}>
                        {selectedDurations.length > 0 ? selectedDurations.join(", ") : "Select Durations"}
                        <FontAwesomeIcon icon={openDropdown === "duration" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "duration" && (
                        <div className="checkbox-dropdown-content-final">
                          {durations.map((duration) => (
                            <label key={duration}>
                              <input
                                type="checkbox"
                                checked={selectedDurations.includes(duration)}
                                onChange={() => handleCheckboxSelection(selectedDurations, setSelectedDurations, duration)}
                              />
                              {duration}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dropdown-final">
                    <label htmlFor="theme-final">Theme:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("theme")}>
                        {selectedThemes.length > 0 ? selectedThemes.join(", ") : "Select Themes"}
                        <FontAwesomeIcon icon={openDropdown === "theme" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "theme" && (
                        <div className="checkbox-dropdown-content-final">
                          {themes.map((theme) => (
                            <label key={theme}>
                              <input
                                type="checkbox"
                                checked={selectedThemes.includes(theme)}
                                onChange={() => handleCheckboxSelection(selectedThemes, setSelectedThemes, theme)}
                              />
                              {theme}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} className='filter-right-final'>
                <div className="sort-by-final">
                  <div className="dropdown-final">
                    <label htmlFor="sort-by-final">Sort By:</label>
                    <div className="custom-checkbox-dropdown-final">
                      <button className="dropdown-btn-final" onClick={() => toggleDropdown("sort")}>
                        {selectedSorts.length > 0 ? selectedSorts.join(", ") : "Select Sort Options"}
                        <FontAwesomeIcon icon={openDropdown === "sort" ? faChevronUp : faChevronDown} />
                      </button>
                      {openDropdown === "sort" && (
                        <div className="checkbox-dropdown-content-final">
                          {sorts.map((sort) => (
                            <label key={sort}>
                              <input
                                type="checkbox"
                                checked={selectedSorts.includes(sort)}
                                onChange={() => handleCheckboxSelection(selectedSorts, setSelectedSorts, sort)}
                              />
                              {sort}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="package-grid final-pkg">
                {golfHolidays ? golfHolidays.map((holidays, index) => (
                  <HolidayList
                  holidays={holidays}
                />
                )): <div style={{ display: 'flex', justifyContent: 'center' }} >
                <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                  No Golf Holiday Found !
                </Typography>
              </div>}
              </div>
          </Grid>
        </Grid>

      </div>
    </div >
  )
}

export default GolfHolidays;
